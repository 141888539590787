import { IconButton, MenuItem, Skeleton, TableCell, TableRow, TextField, Tooltip, InputAdornment } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IProject, IProjectAndActivity } from '../../interface/IProjects'
import { IJournal, IJournalLine } from '../../interface/IJournal'
import { IOrdersRef } from '../../interface/IOrder'
import { IUser } from '../../interface/IUser'
import { setSelectedJournal } from '../../source/slice/JournalSlice'
import { EmptyGuid } from '../../config/Values'
import { useState } from 'react'

const JournalLine = (props: any) => {
    const { row, id } = props as { row: IJournalLine; id: number }
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const loadingDate: boolean = useSelector((state: any) => state.loadingDate?.value)
    const projects: any = useSelector((state: any) => state.projects?.value)
    const orders: IOrdersRef[] = useSelector((state: any) => state.orders?.value)
    const selectedJournal: IJournal = useSelector((state: any) => state.journal?.value)
    const user: IUser = useSelector((state: any) => state.user?.value)
    const [fixAmountDecimals, setFixAmountDecimals] = useState<boolean>(true)

    const journal: any = useSelector((state: any) => state.journal?.value)

    const handleDeleteLine = (lineno: number, fieldName: string) => {
        handleLineChange(lineno, fieldName, true)
    }

    const handleCopyLine = (line: IJournalLine) => {
        const newLine = { ...line, mserp_projjournaltransentityid: EmptyGuid }
        const newJournal = { ...selectedJournal, lines: [...(selectedJournal?.lines || []), newLine] }
        dispatch(setSelectedJournal(newJournal))
    }

    const handleDropdownChange = (lineno: number, fieldName: string) => (event: any) => {
        const value = event.target.value
        handleLineChange(lineno, fieldName, value)
    }

    //const handleDateChange = (id: number, fieldName: string) => (event: any) => {}

    const handleLineChange = (lineno: number, fieldName: string, newValue: any) => {
        var newjournallines: IJournalLine[] | undefined = journal?.lines
        if (newjournallines !== undefined) {
            let newtimecardlinesCopy = [...newjournallines]
            switch (fieldName) {
                case 'mserp_projectid':
                    newtimecardlinesCopy[lineno] = {
                        ...newtimecardlinesCopy[lineno],
                        mserp_projectid: newValue,
                        mserp_activitynumber: '',
                    }
                    break

                case 'mserp_activitynumber':
                    newtimecardlinesCopy[lineno] = {
                        ...newtimecardlinesCopy[lineno],
                        mserp_activitynumber: newValue,
                    }
                    break

                case 'mserp_purchid':
                    /* newtimecardlinesCopy[lineno] = {
                        ...newtimecardlinesCopy[lineno],
                        mserp_purchid: newValue.split('_')[0],
                        mserp_purchlinenumber: Number(newValue.split('_')[1]),
                    } */
                    break

                default:
                    newtimecardlinesCopy[lineno] = {
                        ...newtimecardlinesCopy[lineno],
                        [fieldName]: newValue,
                    }
                    break
            }

            var newJournal = JSON.parse(JSON.stringify(journal))
            if (newJournal !== undefined && newJournal.lines !== undefined) {
                newJournal.lines = newtimecardlinesCopy
            }
            dispatch(setSelectedJournal(newJournal))
        }
    }

    const displayRow = !row.delete

    return displayRow ? (
        <TableRow key={row.mserp_projjournaltransentityid} sx={{ '&:last-child td': { border: 0 } }}>
            <TableCell sx={{ padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>
                <Tooltip title={t('zdelines.delete')}>
                    <IconButton size="small" onClick={() => handleDeleteLine(id, 'delete')} disabled={selectedJournal.header?.mserp_posted}>
                        <DeleteOutlineIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('zdelines.copy')}>
                    <IconButton size="small" onClick={() => handleCopyLine(row)} disabled={selectedJournal.header?.mserp_posted}>
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
            <TableCell sx={{ padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>
                {!loadingDate ? (
                    !selectedJournal.header?.mserp_posted ? (
                        <TextField
                            select
                            value={row.mserp_projectid}
                            size="small"
                            fullWidth
                            onChange={handleDropdownChange(id, 'mserp_projectid')}
                            disabled={selectedJournal.header?.mserp_posted}
                            error={selectedJournal.lines ? selectedJournal.lines[id]['mserp_projectid'] === '' : true}
                        >
                            {projects?.map((project: IProject, i: number) => (
                                <MenuItem key={project.mserp_projectid + `_` + i} value={project.mserp_projectid} selected>
                                    {project.mserp_projectid} - {project.mserp_projectname}
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : (
                        <TextField
                            value={(() => {
                                const projectId = row.mserp_projectid
                                const project = projects.find((item: IProject) => item.mserp_projectid === projectId)
                                return project ? `${project.mserp_projectid} - ${project.mserp_projectname}` : `${projectId}`
                            })()}
                            size="small"
                            fullWidth
                            disabled={true}
                        />
                    )
                ) : (
                    <Skeleton variant="text" />
                )}
            </TableCell>
            <TableCell sx={{ padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>
                {!loadingDate ? (
                    !selectedJournal.header?.mserp_posted ? (
                        <TextField
                            select
                            value={row.mserp_activitynumber}
                            size="small"
                            fullWidth
                            onChange={handleDropdownChange(id, 'mserp_activitynumber')}
                            disabled={selectedJournal.header?.mserp_posted}
                            error={selectedJournal.lines ? selectedJournal.lines[id]['mserp_activitynumber'] === '' : true}
                        >
                            {journal?.lines ? (
                                projects
                                    ?.filter((proj: IProject) => proj.mserp_projectid === row.mserp_projectid)
                                    ?.map((project: IProject) =>
                                        project.activities?.map((activity: IProjectAndActivity, i: number) => (
                                            <MenuItem key={activity?.mserp_projectid + `_` + i} value={activity?.mserp_activitynumber} selected>
                                                {activity?.mserp_description}
                                            </MenuItem>
                                        ))
                                    )
                            ) : (
                                <></>
                            )}
                        </TextField>
                    ) : (
                        <TextField
                            value={(() => {
                                const projectId = row.mserp_projectid
                                const project = projects.find((item: IProject) => item.mserp_projectid === projectId)
                                const activity: IProjectAndActivity | undefined = project?.activities?.find((item: IProjectAndActivity) => item.mserp_jobid === row.mserp_activitynumber)
                                return activity ? `${activity.mserp_description}` : `${row.mserp_activitynumber}`
                            })()}
                            size="small"
                            fullWidth
                            disabled={true}
                        />
                    )
                ) : (
                    <Skeleton variant="text" />
                )}
            </TableCell>
            {user?.worker?.mserp_employmentcategory === 'FAK' ? (
                <TableCell sx={{ padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>
                    {!loadingDate ? (
                        <TextField
                            select
                            value={row.mserp_mbpurchlinerefrecid}
                            size="small"
                            fullWidth
                            onChange={handleDropdownChange(id, 'mserp_mbpurchlinerefrecid')}
                            disabled={selectedJournal.header?.mserp_posted}
                            error={selectedJournal.lines ? selectedJournal.lines[id]['mserp_mbpurchlinerefrecid'] === 0 : true}
                        >
                            {orders?.map((order: IOrdersRef, i: number) => (
                                <MenuItem key={order.mserp_purchid + `_` + i} value={order.mserp_purchlinerecid} selected>
                                    {order.mserp_purchid} - {order.mserp_linenumber}
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : (
                        <Skeleton variant="text" />
                    )}
                </TableCell>
            ) : (
                <></>
            )}
            <TableCell sx={{ padding: { xs: '16px 16px 16px 8px', md: '16px', lg: '16px 16px 16px 8px', xl: '16px' } }}>
                {!loadingDate ? (
                    <TextField
                        value={fixAmountDecimals ? (Math.floor(Math.round(row.mserp_hours ? row.mserp_hours * 10000 : 0) / 100) / 100).toFixed(2) : Math.floor(Math.round(row.mserp_hours ? row.mserp_hours * 10000 : 0) / 100) / 100}
                        type="number"
                        size="small"
                        disabled={selectedJournal.header?.mserp_posted}
                        sx={{ width: '120px' }}
                        slotProps={{
                            htmlInput: { style: { textAlign: 'center' }, min: 0 },
                            input: {
                                endAdornment: <InputAdornment position="start">h</InputAdornment>,
                            },
                        }}
                        onBlur={(e: any) => setFixAmountDecimals(true)}
                        onChange={(e: any) => {
                            setFixAmountDecimals(false)
                            handleLineChange(id, 'mserp_hours', Math.floor(Number((e.target.value * 100).toFixed(1))) / 100)
                        }}
                        error={selectedJournal.lines && selectedJournal.lines[id]['mserp_hours'] ? selectedJournal.lines[id]['mserp_hours'] < 0 || selectedJournal.lines[id]['mserp_hours'] % 0.25 !== 0 : true}
                        helperText={selectedJournal.lines && selectedJournal.lines[id]['mserp_hours'] % 0.25 !== 0 ? t('zdelines.journalinvalidhours') : ''}
                    />
                ) : (
                    <Skeleton variant="text" />
                )}
            </TableCell>
        </TableRow>
    ) : (
        <></>
    )
}

export default JournalLine
