import { Box, Button, Chip, Divider, Skeleton, Typography } from '@mui/material'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import JournalLines from './JournalLines'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IJournal } from '../../interface/IJournal'
import { IUser } from '../../interface/IUser'
import { format } from 'date-fns'
import { de } from 'date-fns/locale/de'
import { enUS } from 'date-fns/locale/en-US'
import axios, { AxiosRequestConfig } from 'axios'
import { useMsal } from '@azure/msal-react'
import { setAlert } from '../../source/slice/AlertSlice'
import { ICustomSnackbar } from '../../interface/ICustomSnackbar'
import { UpdateStoreHelperJournal } from '../../source/StoreHelperJournal'
import { InteractionRequiredAuthError, SilentRequest } from '@azure/msal-browser'
import { setLoadingDate } from '../../source/slice/LoadingDateSlice'

const JournalHeader = (props: any) => {
    const { t, i18n } = useTranslation()
    const journal: IJournal = useSelector((state: any) => state.journal?.value)
    const user: IUser = useSelector((state: any) => state.user?.value)
    const loadingDate: boolean = useSelector((state: any) => state.loadingDate?.value)
    const { instance, accounts } = useMsal()
    const dispatch = useDispatch()

    const formatDate = () => {
        return i18n.language === 'de' ? (
            format(new Date(journal?.header?.date ?? new Date().toISOString()), 'EEEE, dd.MM.yyyy', {
                locale: de,
            })
        ) : (
            format(new Date(journal?.header?.date ?? new Date().toISOString()), 'EEEE, MM/dd/yyyy', {
                locale: enUS,
            })
        )
    }

    const customSnackbarMessageSuccess: ICustomSnackbar = {
        message: t('zdeheader.deleteSuccess'),
        type: 'success',
    }
    
    const customSnackbarMessageError: ICustomSnackbar = {
        message: t('zdeheader.deleteError'),
        type: 'error',
    }

    const accessTokenRequest : SilentRequest = {
        scopes: ['openid', 'profile', 'email', 'user.read'],
        account: accounts[0],
        forceRefresh: true
    }

    const deleteJournal = () => {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        date: journal?.header?.date ? format(journal.header.date, 'yyyy-MM-dd') : '',
                    },
                }
                dispatch(setLoadingDate(true))
                axios
                    .delete(`${process.env.REACT_APP_BACKEND_URL}/Journal`, config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageSuccess))
                            if (journal.header?.date) {
                                updateJournal(journal.header?.date)
                            } else {
                                dispatch(setLoadingDate(false))
                            }
                        } else {
                            dispatch(setAlert(customSnackbarMessageError))
                            dispatch(setLoadingDate(false))
                        }
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                        dispatch(setLoadingDate(false))
                    })
            })
            .catch((error) => {
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
                dispatch(setLoadingDate(false))
            })
    }

    const updateJournal = (date: string) => {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                UpdateStoreHelperJournal(format(date, 'yyyy-MM-dd'), accessToken)
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    return instance.acquireTokenRedirect(accessTokenRequest)
                }
                console.log(error)
                dispatch(setLoadingDate(false))
            })
    }

    const getStatusChip = (status: string) => {
        switch (status) {
            case 'OPEN':
                return <Chip label={t('calendar.open')} color="default" variant="outlined" />
            case 'RELEASED':
                return <Chip label={t('calendar.released')} color="success" variant="filled" />
            case 'SAVED':
                return <Chip label={t('calendar.saved')} color="success" variant="outlined" />
            case 'ABSENCE':
                return <Chip label={t('calendar.absence')} color="primary" style={{ color: 'rgb(107, 0, 138)' }} variant="filled" />
            case 'HOLIDAY':
                return <Chip label={t('calendar.holiday')} color="primary" style={{ color: 'rgb(107, 0, 138)' }} variant="filled" />
            case 'WEEKEND':
                return <Chip label={t('calendar.open')} color="default" variant="outlined" />
            case 'NOTRECORDED':
                return <Chip label={t('calendar.notrecorded')} color="warning" variant="filled" />
            default:
                return ''
        }
    }

    return (
        <>
            <Box display="flex" flexDirection="column" alignItems="start" gap="16px" justifyContent="space-between" width="100%" sx={{ '@media (min-width: 700px)': { flexDirection: 'row', alignItems: 'end', gap: '0' } }}>
                {!loadingDate && user ? (
                    <Typography variant="h4">
                        {formatDate()} | ({user?.resource?.mserp_resourceid}) { getStatusChip(journal?.header?.status || '') }
                    </Typography>
                ) : (
                    <Typography variant="h4">
                        <Skeleton variant="text" sx={{ width: { xs: 200, md: 300 }, display: 'inline-block' }} />
                    </Typography>
                )}
                <Button variant="outlined" onClick={deleteJournal} startIcon={<RestartAltIcon />} color="error" disabled={journal?.header?.mserp_posted}>
                    {t('zdeheader.resetDay')}
                </Button>
            </Box>
            <Divider sx={{ marginTop: '16px' }} />

            <JournalLines />
        </>
    )
}

export default JournalHeader
