import axios, { AxiosRequestConfig } from 'axios'
import { Box, Button, Paper, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Tooltip, TooltipProps, tooltipClasses, styled, Skeleton, Link, Stack } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorIcon from '@mui/icons-material/Error'
import CheckIcon from '@mui/icons-material/Check'
import SaveIcon from '@mui/icons-material/Save'
import UndoIcon from '@mui/icons-material/Undo'
import InfoIcon from '@mui/icons-material/Info'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ITimecard } from '../interface/ISelectedDate'
import { useMsal } from '@azure/msal-react'
import { ICustomSnackbar } from '../interface/ICustomSnackbar'
import { setAlert } from '../source/slice/AlertSlice'
import { format } from 'date-fns'
import React, { useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { ButtonGroup } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { UpdateStoreHelper } from '../source/StoreHelper'
import { ErrorStatusCode } from '../interface/ErrorStatusCode'
import { IJournal } from '../interface/IJournal'
import { UpdateStoreHelperJournal } from '../source/StoreHelperJournal'
import { IUser } from '../interface/IUser'
import { InteractionRequiredAuthError, SilentRequest } from '@azure/msal-browser'
import { setLoadingDate } from '../source/slice/LoadingDateSlice'
import LaunchIcon from '@mui/icons-material/Launch'

const Footer = () => {
    const { t } = useTranslation()
    const selectedDate: ITimecard = useSelector((state: any) => state.selectedDate?.value)
    const selectedJournal: IJournal = useSelector((state: any) => state.journal?.value)
    const user: IUser = useSelector((state: any) => state.user?.value)
    const { instance, accounts } = useMsal()
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [openDialog, setOpenDialog] = useState(false)
    const popoverRef = React.useRef<HTMLDivElement | null>(null)
    const [dialogContentText, setDialogContentText] = useState(t('footer.releaseWithIllnessConfirmation'))

    const isInternal: boolean = user?.worker?.mserp_employmentcategory && (user?.worker?.mserp_employmentcategory === process.env.REACT_APP_USER_INTERNAL || user?.worker?.mserp_employmentcategory === process.env.REACT_APP_USER_STUDENT) ? true : false
    const isStudent: boolean = user?.worker?.mserp_employmentcategory && user?.worker?.mserp_employmentcategory === process.env.REACT_APP_USER_STUDENT ? true : false
    const isANU: boolean = user?.worker?.mserp_employmentcategory && user?.worker?.mserp_employmentcategory === 'ANÜ' ? true : false
    const calendarBlocked: boolean = (selectedDate?.header?.mserp_timeprofileid && selectedDate?.header?.mserp_timeprofileid === 'BLOCK') || (selectedDate?.header?.specialDayId && selectedDate?.header?.specialDayId === 'REDUCE') ? true : false

    const loadingDate: boolean = useSelector((state: any) => state.loadingDate?.value)

    const [backendModalOpen, setBackendModalOpen] = useState(false)
    const [backendModalMessage, setBackendModalMessage] = useState('')

    const [loadingSave, setLoadingSave] = useState(false)
    const [loadingRelease, setLoadingRelease] = useState(false)

    type ErrorCode = 101 | 102 | 103 | 9000 | 9001 | 9002 | 9003 | 9004 | 9005

    const customSnackbarMessageSuccess: ICustomSnackbar = {
        message: t('zdeheader.saveSuccess'),
        type: 'success',
    }
    const customSnackbarMessageError: ICustomSnackbar = {
        message: t('zdeheader.saveError'),
        type: 'error',
    }

    const customSnackbarMessageReleaseSuccess: ICustomSnackbar = {
        message: t('zdeheader.releaseSuccess'),
        type: 'success',
    }
    const customSnackbarMessageReleaseError: ICustomSnackbar = {
        message: t('zdeheader.releaseError'),
        type: 'error',
    }

    const customSnackbarMessageReopenSuccess: ICustomSnackbar = {
        message: t('zdeheader.reopenSuccess'),
        type: 'success',
    }
    const customSnackbarMessageReopenError: ICustomSnackbar = {
        message: t('zdeheader.reopenError'),
        type: 'error',
    }

    const handleClick = () => {
        if (user?.worker?.mserp_usetimecard) {
            postTimecard()
        } else {
            postJournal()
        }
    }

    const handleReleaseClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const simulateBackendResponse = (code: ErrorCode) => {
        const errorMessages = {
            9000: t('footer.backendErrors.maximumtime') + ' ' + t('footer.backendErrors.missingComment'),
            9001: t('footer.backendErrors.breaktime') + ' ' + t('footer.backendErrors.missingComment'),
            9002: t('footer.backendErrors.dayafter') + ' ' + t('footer.backendErrors.missingComment'),
            9003: t('footer.backendErrors.daybefore') + ' ' + t('footer.backendErrors.missingComment'),
            9004: t('footer.backendErrors.unknownError'),
            9005: t('footer.backendErrors.sundaywork') + ' ' + t('footer.backendErrors.missingComment'),
            9101: t('footer.backendErrors.toomuchdistributed'),
            9102: t('footer.backendErrors.toolessdistributed'),
            //9103: t('footer.backendErrors.missingproject'),
            9104: t('footer.backendErrors.missingactivity'),
            9105: t('footer.backendErrors.missingorder'),
            9106: t('footer.backendErrors.missingfromtoheader'),
            9201: t('footer.backendErrors.nocapacity'),
            9202: t('footer.backendErrors.hoursalreadydistributed'),
            9301: t('footer.backendErrors.undefinedreopenerror'),
            9302: t('footer.backendErrors.notpermittedperiod'),
            9401: t('footer.backendErrors.timecardalreadyexists'),
            9402: t('footer.backendErrors.journalalreadyexists'),
            9403: t('footer.backendErrors.journalinvalidhours'),
            9501: t('footer.backendErrors.dateoutsideentryperiod'),
            101: t('footer.backendErrors.missingFields'),
            102: t('footer.backendErrors.missingComment'),
            103: t('footer.backendErrors.unknownError'),
            // to be extended (more codes)
        }

        const simulateError = true

        if (simulateError) {
            const errorMessage = errorMessages[code] || t('footer.backendErrors.unknownError')
            setBackendModalMessage(`${errorMessage}`)
        } else {
            setBackendModalMessage(t('footer.backendResponse'))
        }
        setBackendModalOpen(true)
    }

    const handleReleaseWithIllness = () => {
        // to be extended (backend API call)
        setOpenDialog(true)
        setAnchorEl(null)
    }

    const handleDialogClose = (confirm: boolean) => {
        if (confirm) {
            releaseTimecardWithIllness()
            //simulateBackendResponse(101) // error message code can be changed
            setOpenDialog(false)
        } else {
            setOpenDialog(false)
        }
    }

    const accessTokenRequest: SilentRequest = {
        scopes: ['openid', 'profile', 'email', 'user.read'],
        account: accounts[0],
        forceRefresh: true,
    }

    const postTimecard = () => {
        setLoadingSave(true)
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }
                dispatch(setLoadingDate(true))
                axios
                    .post(`${process.env.REACT_APP_BACKEND_URL}/Timecard`, JSON.stringify(selectedDate), config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageSuccess))
                            if (selectedDate.header?.mserp_timeprofiledate) {
                                updateDate(selectedDate.header?.mserp_timeprofiledate)
                            } else {
                                dispatch(setLoadingDate(false))
                            }
                        } else {
                            dispatch(setAlert(customSnackbarMessageError))
                            dispatch(setLoadingDate(false))
                        }
                        console.log(response)
                    })
                    .catch((error) => {
                        dispatch(setLoadingDate(false))
                        dispatch(setAlert(customSnackbarMessageError))
                        var err: ErrorStatusCode = error.response?.data
                        if (err?.VIOLATION_CODE) {
                            const errorCode: ErrorCode = err?.VIOLATION_CODE as ErrorCode
                            simulateBackendResponse(errorCode)
                        }
                    })
                    .finally(() => {
                        setLoadingSave(false)
                    })
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    return instance.acquireTokenRedirect(accessTokenRequest)
                }
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
                dispatch(setLoadingDate(false))
            })
    }

    const postJournal = () => {
        setLoadingSave(true)
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }
                dispatch(setLoadingDate(true))
                axios
                    .post(`${process.env.REACT_APP_BACKEND_URL}/Journal`, JSON.stringify(selectedJournal), config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageSuccess))
                            if (selectedJournal.header?.date) {
                                updateJournal(selectedJournal.header?.date)
                            } else {
                                dispatch(setLoadingDate(false))
                            }
                        } else {
                            dispatch(setAlert(customSnackbarMessageError))
                            dispatch(setLoadingDate(false))
                        }
                        console.log(response)
                    })
                    .catch((error) => {
                        dispatch(setLoadingDate(false))
                        dispatch(setAlert(customSnackbarMessageError))
                        var err: ErrorStatusCode = error.response?.data
                        if (err?.VIOLATION_CODE) {
                            const errorCode: ErrorCode = err?.VIOLATION_CODE as ErrorCode
                            simulateBackendResponse(errorCode)
                        }
                    })
                    .finally(() => {
                        setLoadingSave(false)
                    })
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    return instance.acquireTokenRedirect(accessTokenRequest)
                }
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
                dispatch(setLoadingDate(false))
            })
    }

    const updateDate = (date: string) => {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                UpdateStoreHelper(format(date, 'yyyy-MM-dd'), accessToken)
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    return instance.acquireTokenRedirect(accessTokenRequest)
                }
                console.log(error)
                dispatch(setLoadingDate(false))
            })
    }

    const updateJournal = (date: string) => {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                UpdateStoreHelperJournal(format(date, 'yyyy-MM-dd'), accessToken)
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    return instance.acquireTokenRedirect(accessTokenRequest)
                }
                console.log(error)
                dispatch(setLoadingDate(false))
            })
    }

    const releaseTimecard = () => {
        setLoadingRelease(true)
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        date: selectedDate?.header?.mserp_timeprofiledate ? format(selectedDate.header.mserp_timeprofiledate, 'yyyy-MM-dd') : '',
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }
                dispatch(setLoadingDate(true))
                axios
                    .post(`${process.env.REACT_APP_BACKEND_URL}/ReleaseTimecard`, JSON.stringify(selectedDate), config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageReleaseSuccess))
                            if (selectedDate.header?.mserp_timeprofiledate) {
                                updateDate(selectedDate.header?.mserp_timeprofiledate)
                            } else {
                                dispatch(setLoadingDate(false))
                            }
                        } else {
                            dispatch(setAlert(customSnackbarMessageReleaseError))
                            dispatch(setLoadingDate(false))
                        }
                        //console.log(response)
                    })
                    .catch((error) => {
                        //simulateBackendResponse(90001)
                        dispatch(setAlert(customSnackbarMessageReleaseError))
                        var err: ErrorStatusCode = error.response?.data
                        if (err?.VIOLATION_CODE) {
                            const errorCode: ErrorCode = err?.VIOLATION_CODE as ErrorCode
                            simulateBackendResponse(errorCode)
                        }
                        dispatch(setLoadingDate(false))
                    })
                    .finally(() => {
                        setLoadingRelease(false)
                    })
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    return instance.acquireTokenRedirect(accessTokenRequest)
                }
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
                dispatch(setLoadingDate(false))
            })
    }

    const reopenTimecard = () => {
        setLoadingRelease(true)
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        date: selectedDate?.header?.mserp_timeprofiledate ? format(selectedDate.header.mserp_timeprofiledate, 'yyyy-MM-dd') : '',
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }
                dispatch(setLoadingDate(true))
                axios
                    .post(`${process.env.REACT_APP_BACKEND_URL}/ReopenTimecard`, null, config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageReopenSuccess))
                            if (selectedDate.header?.mserp_timeprofiledate) {
                                updateDate(selectedDate.header?.mserp_timeprofiledate)
                            } else {
                                dispatch(setLoadingDate(false))
                            }
                        } else {
                            dispatch(setAlert(customSnackbarMessageReopenError))
                            dispatch(setLoadingDate(false))
                        }
                        //console.log(response)
                    })
                    .catch((error) => {
                        //simulateBackendResponse(90001)
                        dispatch(setAlert(customSnackbarMessageReopenError))
                        var err: ErrorStatusCode = error.response?.data
                        if (err?.VIOLATION_CODE) {
                            const errorCode: ErrorCode = err?.VIOLATION_CODE as ErrorCode
                            simulateBackendResponse(errorCode)
                        }
                        dispatch(setLoadingDate(false))
                    })
                    .finally(() => {
                        setLoadingRelease(false)
                    })
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    return instance.acquireTokenRedirect(accessTokenRequest)
                }
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
                dispatch(setLoadingDate(false))
            })
    }

    const releaseTimecardWithIllness = () => {
        setLoadingRelease(true)
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        date: selectedDate?.header?.mserp_timeprofiledate ? format(selectedDate.header.mserp_timeprofiledate, 'yyyy-MM-dd') : '',
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }
                dispatch(setLoadingDate(true))
                axios
                    .post(`${process.env.REACT_APP_BACKEND_URL}/ReleaseTimecardWithIllness`, JSON.stringify(selectedDate), config)
                    .then((response) => {
                        console.log('RESPONSE', response)
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageReleaseSuccess))
                            if (selectedDate.header?.mserp_timeprofiledate) {
                                updateDate(selectedDate.header?.mserp_timeprofiledate)
                            } else {
                                dispatch(setLoadingDate(false))
                            }
                        } else {
                            dispatch(setAlert(customSnackbarMessageReleaseError))
                            dispatch(setLoadingDate(false))
                        }
                        console.log(response)
                    })
                    .catch((error) => {
                        dispatch(setAlert(customSnackbarMessageReleaseError))
                        var err: ErrorStatusCode = error.response?.data
                        if (err?.VIOLATION_CODE) {
                            const errorCode: ErrorCode = err?.VIOLATION_CODE as ErrorCode
                            simulateBackendResponse(errorCode)
                        }
                        dispatch(setLoadingDate(false))
                    })
                    .finally(() => {
                        setLoadingRelease(false)
                    })
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    return instance.acquireTokenRedirect(accessTokenRequest)
                }
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
                dispatch(setLoadingDate(false))
            })
    }

    const releaseJournal = () => {
        setLoadingRelease(true)
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        date: selectedJournal.header?.date ? format(selectedJournal.header?.date, 'yyyy-MM-dd') : '',
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }
                dispatch(setLoadingDate(true))
                axios
                    .post(`${process.env.REACT_APP_BACKEND_URL}/ReleaseJournal`, JSON.stringify(selectedJournal), config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageReleaseSuccess))
                            if (selectedJournal.header?.date) {
                                updateJournal(selectedJournal.header?.date)
                            } else {
                                dispatch(setLoadingDate(false))
                            }
                        } else {
                            dispatch(setAlert(customSnackbarMessageReleaseError))
                            dispatch(setLoadingDate(false))
                        }
                        console.log(response)
                    })
                    .catch((error) => {
                        //simulateBackendResponse(90001)
                        dispatch(setAlert(customSnackbarMessageReleaseError))
                        var err: ErrorStatusCode = error.response?.data
                        if (err?.VIOLATION_CODE) {
                            const errorCode: ErrorCode = err?.VIOLATION_CODE as ErrorCode
                            simulateBackendResponse(errorCode)
                        }
                        dispatch(setLoadingDate(false))
                    })
                    .finally(() => {
                        setLoadingRelease(false)
                    })
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    return instance.acquireTokenRedirect(accessTokenRequest)
                }
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
                dispatch(setLoadingDate(false))
            })
    }

    const TextCenterTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)({
        [`& .${tooltipClasses.tooltip}`]: {
            textAlign: 'center',
            lineHeight: '1.5em',
            padding: '8px 12px',
        },
    })

    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: 0,
                width: '100vw',
            }}
        >
            <Paper sx={{ bottom: 0 }}>
                <Box sx={{ p: 1, py: { xs: '12px', md: 1 }, display: 'flex', gap: 1, justifyContent: { xs: 'space-between', md: 'flex-end' }, alignItems: 'center', flexDirection: { xs: 'column-reverse', md: 'row' } }}>
                    {(isInternal || isANU) &&
                        (selectedDate.totalHours !== undefined ? (
                            <Box display="flex" alignItems="center" sx={{ gap: { xs: '4px', sm: '10px' }, marginRight: { xs: '0', md: '12px' }, fontSize: { xs: '0.875rem', sm: '1rem' }, '@media (max-width: 359px)': { fontSize: '0.8rem' } }}>
                                <TextCenterTooltip
                                    title={
                                        <Link href="https://corpdir.sharepoint.com/sites/mbti-apps-and-tools/SitePages/Gesamtsumme.aspx" color="#fff" target="_blank" rel="noopener">
                                            <Stack alignItems="center" direction="row" gap={1}>
                                                <Typography fontSize="9pt">{t('footer.totalamountdescription')} </Typography>
                                                <LaunchIcon style={{ fontSize: 15 }} />
                                            </Stack>
                                        </Link>
                                    }
                                >
                                    <Box display="flex" alignItems="center" height="fit-content">
                                        {t('footer.totalamount', {
                                            //from: format(new Date(new Date().getFullYear(), 0, 1), 'dd.MM.'),
                                            date: selectedDate.totalHours?.date ? format(new Date(selectedDate.totalHours.date), 'dd.MM.yyyy') : '-',
                                            amount: selectedDate.totalHours.hours.toFixed(2),
                                        })}
                                    </Box>
                                </TextCenterTooltip>
                                {isInternal && (
                                    <Box display="flex">
                                        {!isStudent && (
                                            <>
                                                {selectedDate.totalHours.hours < -60 && (
                                                    <TextCenterTooltip title={t('footer.redLightMinus')}>
                                                        <ErrorIcon color="error" sx={{ width: { xs: '0.9em', sm: '1em' }, height: { xs: '0.9em', sm: '1em' } }} />
                                                    </TextCenterTooltip>
                                                )}
                                                {selectedDate.totalHours.hours >= -60 && selectedDate.totalHours.hours < -40 && (
                                                    <TextCenterTooltip title={t('footer.yellowLightMinus')}>
                                                        <WarningIcon color="warning" sx={{ width: { xs: '0.9em', sm: '1em' }, height: { xs: '0.9em', sm: '1em' } }} />
                                                    </TextCenterTooltip>
                                                )}
                                                {selectedDate.totalHours.hours >= -40 && selectedDate.totalHours.hours <= 120 && (
                                                    <TextCenterTooltip title={t('footer.greenLightInfo')}>
                                                        <CheckCircleIcon color="success" sx={{ width: { xs: '0.9em', sm: '1em' }, height: { xs: '0.9em', sm: '1em' } }} />
                                                    </TextCenterTooltip>
                                                )}
                                                {selectedDate.totalHours.hours > 120 && selectedDate.totalHours.hours <= 160 && (
                                                    <TextCenterTooltip title={t('footer.yellowLightPlus')} sx={{ textWrap: 'balance', textAlign: 'center' }}>
                                                        <WarningIcon color="warning" sx={{ width: { xs: '0.9em', sm: '1em' }, height: { xs: '0.9em', sm: '1em' } }} />
                                                    </TextCenterTooltip>
                                                )}
                                                {selectedDate.totalHours.hours > 160 && (
                                                    <TextCenterTooltip title={t('footer.redLightPlus')}>
                                                        <ErrorIcon color="error" sx={{ width: { xs: '0.9em', sm: '1em' }, height: { xs: '0.9em', sm: '1em' } }} />
                                                    </TextCenterTooltip>
                                                )}
                                            </>
                                        )}
                                        {isStudent && (
                                            <>
                                                {selectedDate.totalHours.hours < -20 && (
                                                    <TextCenterTooltip title={t('footer.redLightMinus')}>
                                                        <ErrorIcon color="error" sx={{ width: { xs: '0.9em', sm: '1em' }, height: { xs: '0.9em', sm: '1em' } }} />
                                                    </TextCenterTooltip>
                                                )}
                                                {selectedDate.totalHours.hours >= -20 && selectedDate.totalHours.hours <= 40 && (
                                                    <TextCenterTooltip title={t('footer.greenLightInfo')}>
                                                        <CheckCircleIcon color="success" sx={{ width: { xs: '0.9em', sm: '1em' }, height: { xs: '0.9em', sm: '1em' } }} />
                                                    </TextCenterTooltip>
                                                )}
                                                {selectedDate.totalHours.hours > 40 && (
                                                    <TextCenterTooltip title={t('footer.redLightPlus')}>
                                                        <ErrorIcon color="error" sx={{ width: { xs: '0.9em', sm: '1em' }, height: { xs: '0.9em', sm: '1em' } }} />
                                                    </TextCenterTooltip>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <Skeleton variant="text" sx={{ maxWidth: 'calc(100% - 32px)', width: 300 }} />
                        ))}
                    {!selectedDate?.header?.isTransferred && !selectedDate?.header?.calculated && !selectedDate?.dayInformation?.mserp_transferred ? (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button
                                variant="outlined"
                                size="medium"
                                loading={loadingSave}
                                loadingPosition="end"
                                onClick={handleClick}
                                sx={{ ml: { xs: 0, md: 1 }, mr: { xs: '6px', sm: 1 } }}
                                startIcon={<SaveIcon sx={{ '@media (max-width: 359px)': { display: 'none' } }} />}
                                disabled={selectedDate?.header?.isTransferred || selectedDate?.dayInformation?.mserp_transferred || calendarBlocked || selectedJournal?.header?.mserp_posted || loadingDate}
                            >
                                <>
                                    {t('footer.save')}
                                    <TextCenterTooltip title={t('footer.saveinfo')} placement="top">
                                        <InfoIcon color="disabled" fontSize="small" sx={{ ml: 1 }} />
                                    </TextCenterTooltip>
                                </>
                            </Button>
                            <div ref={popoverRef} style={{ position: 'absolute', top: 0, left: 0 }}></div>
                            <ButtonGroup variant="contained" sx={{ marginRight: { xs: '0', md: '24px' } }}>
                                <Button
                                    size="medium"
                                    loading={loadingRelease}
                                    loadingPosition="end"
                                    onClick={user?.worker?.mserp_usetimecard ? releaseTimecard : releaseJournal}
                                    startIcon={<CheckIcon sx={{ '@media (max-width: 359px)': { display: 'none' } }} />}
                                    disabled={selectedDate?.header?.isTransferred || selectedDate?.dayInformation?.mserp_transferred || calendarBlocked || selectedJournal?.header?.mserp_posted || loadingDate}
                                >
                                    {t('footer.release')}
                                </Button>

                                {isInternal && (
                                    <Button size="small" onClick={handleReleaseClick} disabled={selectedDate?.header?.isTransferred || selectedDate?.dayInformation?.mserp_transferred || calendarBlocked || loadingDate}>
                                        <ArrowDropDownIcon />
                                    </Button>
                                )}
                            </ButtonGroup>
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            {user?.worker?.mserp_usetimecard ? (
                                <Button
                                    variant="contained"
                                    size="medium"
                                    loading={loadingRelease}
                                    loadingPosition="end"
                                    onClick={reopenTimecard}
                                    startIcon={<UndoIcon sx={{ '@media (max-width: 359px)': { display: 'none' } }} />}
                                    disabled={(!selectedDate?.header?.isTransferred && !selectedDate?.header?.calculated && !selectedDate?.dayInformation?.mserp_transferred) || calendarBlocked || loadingDate}
                                >
                                    {t('footer.reopen')}
                                </Button>
                            ) : (
                                <></>
                            )}
                        </Box>
                    )}
                    {isInternal && (
                        <Menu id="release-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                            <MenuItem onClick={handleReleaseWithIllness}>{t('footer.releaseWithIllness')}</MenuItem>
                        </Menu>
                    )}
                </Box>
            </Paper>

            <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
                <DialogTitle>{t('footer.releaseWithIllness')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('footer.releaseWithIllnessConfirmation')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose(false)} color="primary">
                        {t('footer.cancel')}
                    </Button>
                    <Button onClick={() => handleDialogClose(true)} color="primary">
                        {t('footer.release')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={backendModalOpen} onClose={() => setBackendModalOpen(false)}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <ErrorOutlineIcon color="error" sx={{ marginRight: '8px' }} />
                        <Typography variant="h6" component="div">
                            {t('footer.error')}
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{backendModalMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setBackendModalOpen(false)} color="primary">
                        {t('footer.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}

export default Footer
